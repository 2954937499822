export enum Age {
  CHILDREN = 'children',
  ADULTS = 'adults',
  PRESCHOOL = 'preschool',
  FOLKHIGHSCOOL = 'folkhighschool',
  KOMVUXGRUNDVUX = 'komvuxgrundvux',
  SCHOOLVUX = 'schoolvux',
  PRIMARY03 = 'primary0-3',
  PRIMARY46 = 'primary4-6',
  PRIMARY79 = 'primary7-9',
  SECONDARY = 'secondary',
  SPECIALSCHOOLADULTS = 'specialschooladults',
  SPECIALSCHOOLPRIMARY03 = 'specialschoolprimary0-3',
  SPECIALSCHOOLPRIMARY46 = 'specialschoolprimary4-6',
  SPECIALSCHOOLPRIMARY79 = 'specialschoolprimary7-9',
  SPECIALSCHOOLSECONDARY = 'specialschoolsecondary',
  TEACHEREDUCATION = 'teachereducation',
  UNIVERSITY = 'university',
}

export enum TeacherResourceType {
  INTERACTIVE = 'interactive',
  QUIZ = 'quiz',
  WORKSHEET = 'worksheet',
  TEACHERGUIDE = 'teacherguide',
  SCRIPT = 'script',
}

export enum ProductType {
  SERIES = 'series',
  PROGRAM = 'program',
  TRAILER = 'trailer',
  SUPER_SERIES = 'super_series',
}

export enum SearchResponseType {
  LIMITED = 'limited',
  EXTENDED = 'extended',
}

export enum MediaFormat {
  VIDEO = 'video',
  AUDIO = 'audio',
}

export enum SortOptions {
  MOST_VIEWED = 'most_viewed',
  MOST_SHARED = 'most_shared',
  PUBLISHED = 'published',
  TITLE = 'title',
  MOST_RELEVANT = 'score_and_pubdate',
  AVAILABILITY_ENDING = 'availability_ending',
}

export enum Platform {
  EMBED = 'embed',
  URACCESS = 'uraccess',
  URPLAY = 'urplay',
}

export enum ContentType {
  WORKSHEET = 'worksheet',
  TEACHERGUIDE = 'teacherguide',
  SCRIPT = 'script',
  QUIZ = 'quiz',
}
