import Head from 'next/head'

const ApplicationMetadata = () => {
  return (
    <Head>
      <link
        rel="preload"
        href="/fonts/Exo2-800.woff2"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/Roboto-300.woff2"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/Roboto-500.woff2"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/Roboto-900.woff2"
        as="font"
        crossOrigin=""
      />
      <link rel="icon" href="/favicon.ico" />
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <meta name="format-detection" content="telephone=no" />
      <meta name="apple-itunes-app" content="app-id=1033474398" />
      <link
        rel="icon"
        sizes="192x192"
        href="/touch-icons/touch-icon-192x192.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="192x192"
        href="/touch-icons/touch-icon-192x192.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/touch-icons/touch-icon-180x180.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/touch-icons/touch-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/touch-icons/touch-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/touch-icons/touch-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/touch-icons/touch-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="/touch-icons/touch-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="/touch-icons/touch-icon-72x72.png"
      />
      <link rel="shortcut icon" href="/favicon.ico" />
      <link rel="manifest" href="/manifest.json" />
    </Head>
  )
}

export default ApplicationMetadata
