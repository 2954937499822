export enum SearchResponseType {
  LIMITED = 'limited',
  EXTENDED = 'extended',
}

export enum ProductListLayout {
  SHOW_LESS = 'show_less',
  SHOW_MORE = 'show_more',
}

export enum ResponsiveImageType {
  CARD = 'card',
  FMG = 'fmg',
  SINGLE = 'single',
}

export enum SwedishLanguageCode {
  EASY_SWEDISH = 'easy-SWE',
  SWEDISH = 'swe',
  SWEDISH_SIGN = 'sgn-SWE',
}

export enum FilterType {
  FORMAT = 'format',
  EDUCATION_LEVEL = 'educationLevel',
  LANGUAGE = 'language',
  SCHOOL_SUBJECT = 'schoolSubject',
  KNOWLEDGE_AREA = 'knowledgeArea',
  SIGN_LANGUAGE_AND_AUDIO_DESCRIPTION = 'signLanguageAndAudioDescription',
  TEACHER_RESOURCE = 'teacherResource',
  SORT = 'sort',
}

export enum ToggleParams {
  HAS_TEACHER_RESOURCE = 'has_teacher_resource',
  IS_AUDIO_DESCRIBED = 'is_audio_described',
  IS_SIGN_LANGUAGE_INTERPRETED = 'is_sign_language_interpreted',
}

export enum InterpretationLabel {
  AUDIO_DESCRIBED = 'Syntolkat',
  SIGN_LANGUAGE_INTERPRETED = 'Teckenspråkstolkat',
}

export enum Direction {
  Up,
  Down,
  Right,
}

export enum NavigationLabel {
  CATEGORIES = 'Kategorier',
  CHILDREN = 'Barn',
  ALL_PROGRAMS = 'Alla program',
  MY_PAGE = 'Min sida',
  EDUCATION = 'Lärare',
}

export enum Categories {
  CHILDREN = 'barn',
  DOCUMENTARIES = 'dokumentar',
  LECTURES = 'forelasningar',
  CULTURE_HISTORY = 'kultur-och-historia',
  SOCIETY = 'samhalle',
  AUDIO_DESCRIPTION = 'syntolkat',
  SIGN_INTERPRETED = 'teckensprak',
  SCIENCE = 'vetenskap',
  AUDIO = 'radio',
}

export enum Age {
  CHILDREN = 'children',
  ADULTS = 'adults',
  PRESCHOOL = 'preschool',
  FOLKHIGHSCOOL = 'folkhighschool',
  KOMVUXGRUNDVUX = 'komvuxgrundvux',
  SCHOOLVUX = 'schoolvux',
  PRIMARY03 = 'primary0-3',
  PRIMARY46 = 'primary4-6',
  PRIMARY79 = 'primary7-9',
  SECONDARY = 'secondary',
  SPECIALSCHOOLADULTS = 'specialschooladults',
  SPECIALSCHOOLPRIMARY03 = 'specialschoolprimary0-3',
  SPECIALSCHOOLPRIMARY46 = 'specialschoolprimary4-6',
  SPECIALSCHOOLPRIMARY79 = 'specialschoolprimary7-9',
  SPECIALSCHOOLSECONDARY = 'specialschoolsecondary',
  TEACHEREDUCATION = 'teachereducation',
  UNIVERSITY = 'university',
}

export enum ProductType {
  SERIES = 'series',
  PROGRAM = 'program',
  TRAILER = 'trailer',
  SUPER_SERIES = 'super_series',
}

export enum ProductTypeLabel {
  SERIES = 'serien',
  PROGRAM = 'programmet',
}

export enum MediaFormat {
  VIDEO = 'video',
  AUDIO = 'audio',
}

export enum MediaFormatLabel {
  VIDEO = 'Titta',
  AUDIO = 'Lyssna',
  ALL = 'Allt',
}

export enum SortOptions {
  MOST_VIEWED = 'most_viewed',
  MOST_SHARED = 'most_shared',
  PUBLISHED = 'published',
  TITLE = 'title',
  AVAILABILITY_ENDING = 'availability_ending',
}

export enum DefaultOptions {
  EMPTY = '',
}

export enum SubmissionState {
  Initial,
  Succeed,
  Failed,
}

export enum MediaQuery {
  isMobile = '(max-width: 639px)',
  isPhabletAndLarger = '(min-width: 640px)',
  isTabletPortraitAndLarger = '(min-width: 768px)',
  isDesktopAndLarger = '(min-width: 1201px)',
  isBigScreenAndLarger = '(min-width: 1441px)',
  isMaxWidthAndLarger = '(min-width: 1601px)',
}

export enum ResponseStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  RESOLVED = 'resolved',
  REJECTED = 'rejected',
}

export enum RouteProductType {
  SERIES = 'serie',
  PROGRAM = 'program',
  TRAILER = 'trailer',
}

export enum RoutePathname {
  CATEGORY = '/bladdra/[...categoryPath]',
  PRODUCT = '/[productType]/[id]',
  SUBJECT = '/amne/[...subjectPath]',
  PEDAGOGICAL_SUPPORT = '/[productType]/[id]/extra',
  EDUCATION_LEVEL = '/utbildning/[educationalLevel]',
  CHILD_AGE_RANGE = '/barn/[childSlug]',
}

export enum VersionNameTranslations {
  ORIGINAL = 'utan tolkning',
  SIGN_LANGUAGE = 'teckenspråkstolkat',
  AUDIO_DESCRIPTION = 'syntolkat',
}

export enum DataLayerPageType {
  START_PAGE = 'start_page',
  VIDEO_PAGE = 'video_page',
  AUDIO_PAGE = 'audio_page',
  MAIN_SUBJECT_PAGE = 'main_subject_page',
  SUBJECT_PAGE = 'subject_page',
  CHILD_LOCK_PAGE = 'child_lock_page',
  CATEGORY_PAGE = 'category_page',
  ALL_PROGRAMS_PAGE = 'all_programs_page',
  EMBED_PAGE = 'embed_page',
  MY_PAGE = 'my_page',
  PEDAGOGICAL_SUPPORT_PAGE = 'pedagogical_support_page',
  SEARCH_PAGE = 'search_page',
  EDUCATIONAL_PAGE = 'educational_page',
  MAIN_CHILD_PAGE = 'main_child_page',
  CHILD_PAGE = 'child_page',
}

export enum KeyCodes {
  ESCAPE_KEY = 'Escape',
  TAB_KEY = 'Tab',
  DOWN_ARROW_KEY_CODE = 'ArrowDown',
  UP_ARROW_KEY_CODE = 'ArrowUp',
  ENTER_KEY = 'Enter',
}
