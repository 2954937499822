import { format, parseISO } from 'date-fns'

export const formatTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  const date = new Date(0, 0, 0, hours, minutes, remainingSeconds)

  return hours && hours > 0
    ? format(date, "hh':'mm':'ss")
    : format(date, "mm':'ss")
}

export const dateStringToSwedishLocaleTimeZoneString = (
  dateTimeString: string,
  formatOptions: {
    year?: 'numeric'
    month?: 'numeric' | 'short' | 'long'
    day?: 'numeric'
    hour?: 'numeric'
    minute?: 'numeric'
    second?: 'numeric'
  },
) =>
  parseISO(dateTimeString).toLocaleString('sv-SE', {
    timeZone: 'Europe/Stockholm',
    ...formatOptions,
  })

export const todayAsSwedishLocaleString = () => {
  const todayString = new Date().toISOString()

  return dateStringToSwedishLocaleTimeZoneString(todayString, {})
}
