import { EdithBlockType, EdithBlockLayout } from './edithService.enums'
import {
  ProgramV2,
  SearchParams,
  SeriesV2,
  Trailer,
} from './urApiService.types'

export interface SubCategoryBase {
  slug: string
  name: string
  prioritized: boolean
}

export interface CategoryTag {
  name: string
  query: SearchParams
  slug: string
  link: string
  level: number
}

interface CategoryBase {
  slug: string
  description: string
  iconUrl: string | null
  imageUrl: string | null
  imageUrlLarge: string | null
  name: string
  searchQueryParams: SearchQueryParams
  seoDescription: string
  seoTitle: string
  streamingPlatform: string
  subCategories?: SubCategoryBase[]
  parentCategory?: { slug: string; name: string }
  updatedAt: string
  tags: CategoryTag[]
}

export interface CategoryOverview extends CategoryBase {
  activeStartPage: ActiveStartPage
  nextStartPage?: null
  prioritized: boolean
}

export interface Category extends CategoryBase {
  startPage: StartPage | null
  tags: CategoryTag[]
}

export interface StartPage {
  active: boolean
  blocks: Block[]
  endAt?: string | null
  id: number
  notes: string
  publishedAt: string
  slug: string
  title: string
  updatedAt: string
}

export enum EdithCardLayout {
  DEFAULT = '',
  SQUARE = 'square',
  PORTRAIT = 'portrait',
}

export enum EdithProductType {
  LINK_PRODUCT = 'LinkProduct',
  UR_PRODUCT = 'UrProduct',
}

export interface CategoryForBlock {
  name: string
  slug: string
  imageUrl: string
}

export type EdithBlockProduct =
  | EdithBlockProgram
  | EdithBlockSeries
  | EdithBlockTrailer
  | LinkProduct

export interface Block {
  align?: string
  blockType?: EdithBlockType
  cardLayout?: EdithCardLayout
  categories?: CategoryForBlock[]
  description: string | null
  id?: number
  layout?: EdithBlockLayout
  mainProduct: EdithBlockProduct | null
  products: EdithBlockProduct[]
  searchQuery?: SearchParams
  title: string | null
  showTitle?: boolean | null
  updatedAt?: string
  visibleProducts?: number
  peach_parameters?: {
    endpoint: string
    u?: string
  }
}

export interface LinkProduct {
  description: string
  imageUrl: string
  title: string
  type: string
  url: string
  actionLabel: string | null
  link: string
}

export interface EdithBlockProgram extends ProgramV2 {
  actionLabel: string | null
}

export interface EdithBlockSeries extends SeriesV2 {
  actionLabel: string | null
}

export interface EdithBlockTrailer extends Trailer {
  actionLabel: string | null
}

export interface ActiveStartPage {
  id: number
  active: boolean
  created_at: string
  end_at?: null
  notes: string
  published_at: string
  slug: string
  title: string
  updated_at: string
}

export interface SearchQueryParams {
  platform: string
  typical_age_range?: string
  singles_and_series?: boolean
  main_genre?: string
  main_genre_must_not?: string[]
  sab_category?: string
}

export interface Announcement {
  message: string
  callToAction?: { url: string; urlText: string }
  platforms: string[]
  timestamp: number
}
