import { Platform } from 'backend/services/v1/urApiService.enums'
import { format, isToday, parseISO } from 'date-fns'
import { AccessiblePlatforms } from 'frontend/types'
import { sv } from 'date-fns/locale'
import React from 'react'
import styles from './NotAccessibleErrors.module.css'
import {
  dateStringToSwedishLocaleTimeZoneString,
  todayAsSwedishLocaleString,
} from 'frontend/utils/timeUtils'

interface Props {
  platforms: Platform[]
  accessiblePlatforms: AccessiblePlatforms
}

const onlyUrAccess = (platforms: Platform[]) => {
  const accessiblePlatforms = platforms
  return (
    accessiblePlatforms.length === 1 && accessiblePlatforms[0] === 'uraccess'
  )
}

const isUpcomingProgram = (accessiblePlatforms: AccessiblePlatforms) => {
  const { urplay } = accessiblePlatforms

  if (urplay)
    return (
      new Date(dateStringToSwedishLocaleTimeZoneString(urplay.startTime, {})) >
      new Date(todayAsSwedishLocaleString())
    )
  return false
}

const isExpiredProgram = (accessiblePlatforms: AccessiblePlatforms) => {
  const { urplay } = accessiblePlatforms

  if (urplay)
    return (
      new Date(dateStringToSwedishLocaleTimeZoneString(urplay.endTime, {})) <
      new Date(todayAsSwedishLocaleString())
    )
  return false
}

const invalidMetadata = (platforms: Platform[]) => {
  const accessiblePlatforms = platforms

  return !accessiblePlatforms.includes(Platform.URPLAY)
}

const UrAccess = () => (
  <>
    <p>Detta program finns tillgängligt under UR Access!</p>
    <a
      className={styles.link}
      href="https://kontakt.ur.se/guide/ur-access-och-mediecentraler"
    >
      Läs mer om UR Access och hur du tar del av programmet
    </a>
  </>
)

const NotAccessible = () => <>Programmet är ej tillgängligt på urplay.se</>

const UpcomingToday = ({ startTime }: { startTime: string }) => (
  <>
    Programmet kommer att bli tillgängligt
    <p>
      idag kl{' '}
      {format(parseISO(startTime), 'HH:mm', {
        locale: sv,
      })}
      .
    </p>
  </>
)

const UpcomingAfterToday = ({ startTime }: { startTime: string }) => (
  <>
    Programmet kommer att vara tillgängligt fr.o.m den
    <p>
      {format(parseISO(startTime), "d MMM yyyy 'kl' HH:mm", {
        locale: sv,
      })}
    </p>
  </>
)

export const getNotAccessibleErrors = (
  platforms: Platform[],
  accessiblePlatforms: AccessiblePlatforms,
) => {
  if (onlyUrAccess(platforms)) {
    return <UrAccess />
  }
  if (isUpcomingProgram(accessiblePlatforms)) {
    const startTime = accessiblePlatforms.urplay?.startTime || ''

    if (isToday(parseISO(startTime))) {
      return <UpcomingToday startTime={startTime} />
    }
    return <UpcomingAfterToday startTime={startTime} />
  }
  if (invalidMetadata(platforms) || isExpiredProgram(accessiblePlatforms)) {
    return <NotAccessible />
  }
  return null
}

const NotAccessibleErrors = ({ platforms, accessiblePlatforms }: Props) => (
  <div data-testid="notAccessibleError" className={styles.textWrapper}>
    {getNotAccessibleErrors(platforms, accessiblePlatforms)}
  </div>
)

export default NotAccessibleErrors
