import { EdithProductType } from 'backend/services/v1/edithService.types'
import { ContentType, Platform } from 'backend/services/v1/urApiService.enums'
import { Categories, MediaQuery } from 'frontend/enums'
import {
  AccessiblePlatforms,
  DropdownOption,
  EdithBlockProduct,
  EducationLevelLabelAndValue,
  LinkProduct,
  PanelOption,
  Participant,
  Product,
  Program,
  ScreenSizesType,
  Series,
} from 'frontend/types'
import {
  dateStringToSwedishLocaleTimeZoneString,
  todayAsSwedishLocaleString,
} from './timeUtils'
import { getNotAccessibleErrors } from 'components/Player/components/NotAccessibleErrors/NotAccessibleErrors'

export const getKebabCaseOptionId = (index: number, value: string) =>
  `option-${index}-${value.replace(/\s/g, '-')}`

export const truncate = (string: string, maxLength = 150, ellipsis = '...') => {
  if (string && string.length > maxLength) {
    return `${string
      .substring(0, maxLength - ellipsis.length)
      .trim()}${ellipsis}`
  }

  return string
}

export const activeOptionLabel = (
  list: DropdownOption[] | PanelOption[],
  param: string | string[],
  multiSelect = false,
): string[] => {
  const emptyStringArray: string[] = []

  const stringArrayParams = emptyStringArray
    .concat(param) // Convert param into an array, even if it's a single string
    .filter(p => typeof p === 'string') // Filter out undefined values, only labels with string values can be active
    .map(p => p.toLowerCase())

  const filtered = list.filter(option => {
    return stringArrayParams.includes(option.value.toString().toLowerCase())
  })

  if (!filtered.length) {
    return [] as never[]
  }
  if (multiSelect) return filtered.map(option => option.label)
  return [filtered[0].label]
}

export const windowIsAvailable = () => typeof window !== 'undefined'

export const errorIsEqual = (e1?: Error, e2?: Error) => {
  return e1?.name === e2?.name && e1?.message === e2?.message
}

export const logError = (error: Error) => {
  // Suppress errors in Jest tests
  if (process.env.JEST_WORKER_ID !== undefined) {
    return null
  }
  if (windowIsAvailable() && window.newrelic) {
    return window.newrelic.noticeError(error)
  }
}

export const capitalizeWords = (words: string) => {
  return words.charAt(0).toUpperCase() + words.slice(1)
}

export const capitalize = (terms: string | string[]) => {
  if (Array.isArray(terms) && terms.length) {
    return terms.map(termInArray => capitalizeWords(termInArray)).join(', ')
  }

  if (typeof terms === 'string') {
    return capitalizeWords(terms)
  }

  return ''
}

export const getEdithCategoryMapping = (category: string) => {
  if (category.toLowerCase() === 'dokumentar') return 'dokumentarfilmer'

  return category
}

export const getCategoryFromSlug = (slug: string) => {
  let category = slug.toLowerCase()
  const slugMappings = [{ legacy: 'dokumentarfilmer', current: 'dokumentar' }]
  slugMappings.forEach(element => {
    if (category === element.legacy) {
      category = element.current
    }
  })

  return category as Categories
}

export const detectScreenSize = (): ScreenSizesType => {
  if (windowIsAvailable()) {
    const isMobile = window.matchMedia(MediaQuery.isMobile)
    const isPhabletAndLarger = window.matchMedia(MediaQuery.isPhabletAndLarger)
    const isTabletPortraitAndLarger = window.matchMedia(
      MediaQuery.isTabletPortraitAndLarger,
    )
    const isDesktopAndLarger = window.matchMedia(MediaQuery.isDesktopAndLarger)
    const isBigScreenAndLarger = window.matchMedia(
      MediaQuery.isBigScreenAndLarger,
    )
    const isMaxWidthAndLarger = window.matchMedia(
      MediaQuery.isMaxWidthAndLarger,
    )

    return {
      isMobile: isMobile.matches,
      isPhabletAndLarger: isPhabletAndLarger.matches,
      isTabletPortraitAndLarger: isTabletPortraitAndLarger.matches,
      isDesktopAndLarger: isDesktopAndLarger.matches,
      isBigScreenAndLarger: isBigScreenAndLarger.matches,
      isMaxWidthAndLarger: isMaxWidthAndLarger.matches,
    }
  }

  return {
    isMobile: false,
    isPhabletAndLarger: false,
    isTabletPortraitAndLarger: false,
    isDesktopAndLarger: false,
    isBigScreenAndLarger: false,
    isMaxWidthAndLarger: false,
  }
}

// Using MDN's feature detection: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage
export const checkStorageSupport = (type: string): boolean => {
  let storage
  try {
    storage = window[type]
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    )
  }
}

export const handleDuration = (duration: number) => {
  const hours = duration / 3600
  const minutes = (duration % 3600) / 60
  const seconds = duration % 60

  if (hours >= 1 && minutes) {
    return `${Math.floor(hours)} tim ${Math.floor(minutes)} min`
  }

  if (hours >= 1) {
    return `${Math.floor(hours)} tim`
  }

  if (minutes >= 5) {
    return `${Math.floor(minutes)} min`
  }

  if (!Math.floor(minutes) && seconds) {
    return `${seconds} sek`
  }

  if (minutes && !Math.floor(seconds)) {
    return `${minutes} min`
  }

  if (minutes && seconds) {
    return `${Math.floor(minutes)} min ${seconds} sek`
  }

  return null
}

export const copyToClipboard = (value: string) => {
  if (
    document.queryCommandSupported &&
    document.queryCommandSupported('copy')
  ) {
    const tempInput = document.createElement('INPUT') as HTMLInputElement
    document.getElementsByTagName('body')[0].appendChild(tempInput)
    tempInput.setAttribute('value', value)
    tempInput.select()
    document.execCommand('copy')
    document.getElementsByTagName('body')[0].removeChild(tempInput)
  }
}

export const aggregateParticipants = (participants: Participant[]) => {
  if (!participants) return []

  const uniqueRoles = [
    ...Array.from(new Set(participants.map(item => item.role))),
  ]

  return uniqueRoles.map(role => ({
    role,
    names: participants
      .filter(part => part.role === role)
      .map(({ firstname, lastname }) =>
        [firstname, lastname].filter(Boolean).join(' '),
      ),
  }))
}

export const getFirstAndSecondSchoolSubjects = (schoolSubjects: string[][]) => {
  if (!schoolSubjects) return []

  const subjects = new Set()

  schoolSubjects.forEach(subject => {
    subjects.add(subject[0])
    if (subject[1]) {
      subjects.add(subject[1])
    }
  })

  return [...Array.from(subjects)] as string[]
}

export const getFileTypeName = (fileType: ContentType) => {
  switch (fileType) {
    case ContentType.WORKSHEET:
      return 'Arbetsmaterial'
    case ContentType.TEACHERGUIDE:
      return 'Lärarhandledning'
    case ContentType.SCRIPT:
      return 'Programmanus'
    case ContentType.QUIZ:
      return 'Quiz'
    default:
      return 'Programmaterial'
  }
}

const EDUCATION_LEVELS: EducationLevelLabelAndValue[] = [
  {
    label: 'Förskola',
    value: 'preschool',
  },
  {
    label: 'Grundskola F-3',
    value: 'primary0-3',
  },
  {
    label: 'Grundskola 4-6',
    value: 'primary4-6',
  },
  {
    label: 'Grundskola 7-9',
    value: 'primary7-9',
  },
  {
    label: 'Gymnasieskola',
    value: 'secondary',
  },
  {
    label: 'Högskola',
    value: 'university',
  },
  {
    label: 'Folkhögskola / Studieförbund',
    value: 'folkhighschool',
  },
  {
    label: 'Komvux',
    value: 'komvuxgrundvux',
  },
  {
    label: 'Lärarfortbildning',
    value: 'teachereducation',
  },
  {
    label: 'Allmänbildande',
    value: 'schoolvux',
  },
  {
    label: 'Grundsärskola',
    value: 'specialschoolprimary',
  },
  {
    label: 'Gymnasiesärskola',
    value: 'specialschoolsecondary',
  },
  {
    label: 'Särvux',
    value: 'specialschooladults',
  },
]

export const filterEducationLevels = (query: string) => {
  const lowercasedQuery = query.toLowerCase()

  return EDUCATION_LEVELS.filter(level =>
    level.label.toLowerCase().includes(lowercasedQuery),
  ).slice(0, 3)
}

export function isUrProduct(product: Product): product is Program | Series {
  return !('type' in product && product.type === EdithProductType.LINK_PRODUCT)
}

export function isLinkProduct(product: Product): product is LinkProduct {
  return 'type' in product && product.type === EdithProductType.LINK_PRODUCT
}

export const URPLAY_DOMAIN_NAME = 'urplay.se'
export const EMBED_DOMAIN_NAME = 'embed.ur.se'

export const isProduction = (host = window.location.host) => {
  return host === URPLAY_DOMAIN_NAME || host === EMBED_DOMAIN_NAME
}

export const secondsToMilliseconds = (seconds: number) => {
  return Math.round(seconds * 1000)
}

export const camelToSnakeCase = (value: string) =>
  value.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export const shallowSnakeCasedParams = (object: Record<string, unknown>) =>
  Object.entries(object).reduce(
    (acc, [key, value]) => ({ ...acc, [camelToSnakeCase(key)]: value }),
    {},
  )

export const isExternalLinkProduct = (product: EdithBlockProduct) => {
  return (
    isLinkProduct(product) &&
    !(product.url?.includes('urplay') || product.url?.charAt(0) === '/')
  )
}

export const objectIsEmpty = (object: object): boolean =>
  Object.keys(object).length === 0

export const getCookie = (key: string) => {
  try {
    return (
      document.cookie
        .split(';')
        .find(row => row.trim().startsWith(key))
        ?.split('=')[1] ?? null
    )
  } catch (e) {
    return null
  }
}

export const setCookie = (name: string, value: string, days: number) => {
  let expires = ''

  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = `; expires=${date.toUTCString()}`
  }

  document.cookie = `${name}=${value || ''}${expires}; path=/`
}

export const handleAccessibleUntil = (product: Product): string => {
  if (!(isUrProduct(product) && product.accessiblePlatforms?.urplay?.endTime)) {
    return ''
  }

  return product.accessiblePlatforms.urplay.endTime
}

export const byIdInCustomOrder = (
  order: string[],
): ((left: { id: number }, right: { id: number }) => number) => {
  return (left, right) =>
    order.indexOf(String(left.id)) - order.indexOf(String(right.id))
}

export const onlyUnique = <T>(value: T, index: number, self: T[]): boolean => {
  return self.indexOf(value) === index
}

export const getBackgroundImage = (srcSet = '') => {
  const imageSet = srcSet
    .split(', ')
    .map(str => {
      const [url, dpi] = str.split(' ')
      return `url("${url}") ${dpi}`
    })
    .join(', ')

  return `image-set(${imageSet})`
}

export const checkAccessibilityOnUrPlayRightNow = (
  accessiblePlatforms: AccessiblePlatforms,
): boolean => {
  if (!accessiblePlatforms.urplay) {
    return false
  }

  const today = new Date(todayAsSwedishLocaleString())

  const startTime = new Date(
    dateStringToSwedishLocaleTimeZoneString(
      accessiblePlatforms.urplay.startTime,
      {},
    ),
  )
  const endTime = new Date(
    dateStringToSwedishLocaleTimeZoneString(
      accessiblePlatforms.urplay.endTime,
      {},
    ),
  )

  return today >= startTime && today <= endTime
}

export const isNotAccessible = (
  platforms: Platform[],
  accessiblePlatforms: AccessiblePlatforms,
  isEmbedded: boolean,
) =>
  Boolean(getNotAccessibleErrors(platforms || [], accessiblePlatforms)) &&
  !isEmbedded
