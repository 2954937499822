export function PeachCollectorLoaded() {
  const peachSiteKey =
    window.location.hostname === 'urplay.se'
      ? 'seur000000000055'
      : 'seur000000000054'

  const peachUrl =
    window.location.hostname === 'urplay.se'
      ? 'https://trk01.ur.se/v3/collectcollect?s='
      : 'https://trk01.ur.se/v3-test/test-collect?s='

  if (typeof window._pc === 'undefined') {
    const _pc = (window._pc = new PeachCollector(window))
    const endpoints = [
      {
        sitekey: peachSiteKey,
        // this is the default endpoint at EBU PEACH when no URL is given
        // but you'll always need your sitekey for it!
      },
      {
        url: `${peachUrl}${peachSiteKey}`,
        // this is a custom endpoint, a sitekey is probably not needed
      },
    ]
    _pc.init(endpoints, 'urplay-web').enableTracking().sendPageViewEvent()
  }
}
