import ApplicationMetadata from 'components/ApplicationMetadata/ApplicationMetadata'
import { FC, useEffect } from 'react'
import '../components/Player/jwStyles/skin.css'
import '../styles/globals.css'
import 'frontend/polyfills'
import Script from 'next/script'
import { PeachCollectorLoaded } from 'frontend/lib/peach'
import { getCookie } from 'frontend/utils/helpers'
import { ThemeProvider } from 'next-themes'

interface FunctionalComponentWithTheme extends FC {
  theme?: string
}

type Props = {
  Component: FunctionalComponentWithTheme
  pageProps: React.Attributes
}

function Application({ Component, pageProps }: Props) {
  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_NEW_RELIC_ENABLED === 'true' &&
      window.newrelic
    ) {
      if (process.env.NEXT_PUBLIC_GIT_SHA) {
        window.newrelic.addRelease?.('UR Play', process.env.NEXT_PUBLIC_GIT_SHA)
      }

      const peachCookie = getCookie('_pc_c')
      if (peachCookie) {
        window.newrelic.setUserId?.(peachCookie)
      }
    }
  }, [])

  return (
    <>
      <ApplicationMetadata />
      <ThemeProvider
        forcedTheme={Component.theme}
        themes={['child']}
        enableSystem={false}
      >
        <Component {...pageProps} />
      </ThemeProvider>
      <Script
        src="/peach/peach-collector-1.4.1.min.js"
        onLoad={() => {
          PeachCollectorLoaded()
        }}
      />
    </>
  )
}

export default Application
